var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "중장비작업 상세정보" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
              [
                _c("c-field", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    data: _vm.supWork,
                    plantCd: _vm.workPermit.plantCd,
                    vendorCd: _vm.workPermit.vendorCd,
                    isVendorOrg: true,
                    changeItem: _vm.changeItem,
                    deptValue: "heavyEquipSafetyCheckDeptCd",
                    type: "dept_user",
                    label: "안전조치 확인자",
                    beforeText: "",
                    name: "heavyEquipSafetyCheckUserId",
                  },
                  on: { dataChange: _vm.dataChange },
                  model: {
                    value: _vm.supWork.heavyEquipSafetyCheckUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.supWork, "heavyEquipSafetyCheckUserId", $$v)
                    },
                    expression: "supWork.heavyEquipSafetyCheckUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
              [
                _c("c-field", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    data: _vm.supWork,
                    plantCd: _vm.workPermit.plantCd,
                    vendorCd: _vm.workPermit.vendorCd,
                    isVendorOrg: true,
                    changeItem: _vm.changeItem,
                    deptValue: "heavyEquipManageDeptCd",
                    type: "dept_user",
                    label: "장비관리 책임자",
                    beforeText: "",
                    name: "heavyEquipManageUserId",
                  },
                  on: { dataChange: _vm.dataChange },
                  model: {
                    value: _vm.supWork.heavyEquipManageUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.supWork, "heavyEquipManageUserId", $$v)
                    },
                    expression: "supWork.heavyEquipManageUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
              [
                _c("c-field", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    data: _vm.supWork,
                    plantCd: _vm.workPermit.plantCd,
                    vendorCd: _vm.workPermit.vendorCd,
                    isVendorOrg: true,
                    changeItem: _vm.changeItem,
                    deptValue: "heavyEquipDriveDeptCd",
                    type: "dept_user",
                    label: "운전원",
                    beforeText: "",
                    name: "heavyEquipDriveUserId",
                  },
                  on: { dataChange: _vm.dataChange },
                  model: {
                    value: _vm.supWork.heavyEquipDriveUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.supWork, "heavyEquipDriveUserId", $$v)
                    },
                    expression: "supWork.heavyEquipDriveUserId",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "col-12 q-mb-sm line-text-left" }),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "감독 요구사항",
                    beforeText: "",
                    name: "heavyEquipDirectorRequirements",
                  },
                  on: { dataChange: _vm.dataChange },
                  model: {
                    value: _vm.supWork.heavyEquipDirectorRequirements,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.supWork,
                        "heavyEquipDirectorRequirements",
                        $$v
                      )
                    },
                    expression: "supWork.heavyEquipDirectorRequirements",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "요청부서 요구사항",
                    beforeText: "",
                    name: "heavyEquipRequireDeptRequirements",
                  },
                  on: { dataChange: _vm.dataChange },
                  model: {
                    value: _vm.supWork.heavyEquipRequireDeptRequirements,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.supWork,
                        "heavyEquipRequireDeptRequirements",
                        $$v
                      )
                    },
                    expression: "supWork.heavyEquipRequireDeptRequirements",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: !_vm.isWriting,
                    editable: _vm.editable,
                    label: "지원부서 요구사항",
                    beforeText: "",
                    name: "heavyEquipSupportDeptRequirements",
                  },
                  on: { dataChange: _vm.dataChange },
                  model: {
                    value: _vm.supWork.heavyEquipSupportDeptRequirements,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.supWork,
                        "heavyEquipSupportDeptRequirements",
                        $$v
                      )
                    },
                    expression: "supWork.heavyEquipSupportDeptRequirements",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-table", {
        staticClass: "q-mt-md",
        attrs: {
          title: "안전조치 요구사항",
          columns: _vm.grid.columns,
          gridHeight: _vm.grid.height,
          data: _vm.supWork.checkResults,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          editable: _vm.editable && _vm.isWriting,
          contentsField: _vm.contentsField,
        },
      }),
      _vm.editable && _vm.isWriting
        ? _c(
            "div",
            {
              staticClass:
                "alert alert-info alert-dismissible fade show cursor-pointer",
              staticStyle: {
                "margin-bottom": "13px !important",
                padding: "10px 3px !important",
              },
              attrs: { role: "alert" },
              on: { click: _vm.setPermitData },
            },
            [
              _c(
                "span",
                { staticClass: "q-pl-sm q-pr-md" },
                [
                  _c("q-icon", {
                    attrs: { name: "o_emoji_objects", size: "sm" },
                  }),
                ],
                1
              ),
              _c(
                "q-chip",
                { attrs: { dense: "", color: "teal", "text-color": "white" } },
                [_vm._v(" 발급 ")]
              ),
              _c(
                "q-chip",
                { attrs: { dense: "", color: "teal", "text-color": "white" } },
                [_vm._v(" 협조자 ")]
              ),
              _c(
                "q-chip",
                { attrs: { dense: "", color: "teal", "text-color": "white" } },
                [_vm._v(" 안전조치확인 ")]
              ),
              _vm._v(" 정보를 허가서에서 가져오기 "),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm no-margin",
                attrs: { title: "발급 정보" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "issuedDeptCd",
                          type: "dept_user",
                          label: "발급",
                          beforeText: "",
                          name: "userId1",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.issuedUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.supWork, "issuedUserId", $$v)
                          },
                          expression: "supWork.issuedUserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "approvalDeptCd",
                          type: "dept_user",
                          label: "승인",
                          beforeText: "",
                          name: "userId2",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.approvalUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.supWork, "approvalUserId", $$v)
                          },
                          expression: "supWork.approvalUserId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm no-margin",
                attrs: { title: "협조자 정보" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "relationCooperation1DeptCd",
                          type: "dept_user",
                          label: "협조자-1",
                          beforeText: "",
                          name: "relationCooperation1UserId",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.relationCooperation1UserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "relationCooperation1UserId",
                              $$v
                            )
                          },
                          expression: "supWork.relationCooperation1UserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "relationCooperation2DeptCd",
                          type: "dept_user",
                          label: "협조자-2",
                          beforeText: "",
                          name: "relationCooperation2UserId",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.relationCooperation2UserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "relationCooperation2UserId",
                              $$v
                            )
                          },
                          expression: "supWork.relationCooperation2UserId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm no-margin",
                attrs: { title: "안전조치확인 정보" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "maintenanceDeptCheckDeptCd",
                          type: "dept_user",
                          label: "책임자",
                          name: "maintenanceDeptCheckUserId",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.maintenanceDeptCheckUserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "maintenanceDeptCheckUserId",
                              $$v
                            )
                          },
                          expression: "supWork.maintenanceDeptCheckUserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "maintenanceDeptEntryDeptCd",
                          type: "dept_user",
                          label: "입회자",
                          name: "maintenanceDeptEntryUserId",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.maintenanceDeptEntryUserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "maintenanceDeptEntryUserId",
                              $$v
                            )
                          },
                          expression: "supWork.maintenanceDeptEntryUserId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }